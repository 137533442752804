import { Icon, Modal, TextField } from '@shopify/polaris';
import React, { useCallback, useMemo, useState } from 'react';
import { Graph } from 'src/generated/graph';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import { gql, useMutation } from '@apollo/client';
import { useCustomToast } from 'src/contexts/ToastProvider';

const MUTATE_UPDATE_WAREHOUSE = gql`
  mutation updateWarehouse($id: Int!, $data: WarehouseInput!) {
    updateWarehouse(id: $id, data: $data)
  }
`;

interface Props {
  warehouse: Graph.Warehouse;
  warehouseList?: Graph.Warehouse[];
}

export function WarehouseDriverBonus(props: Props) {
  const { toasts, setToasts } = useCustomToast();
  const defaultBonus = useMemo(() => props.warehouse.driverBonus, [props.warehouse]);
  const [active, setActive] = useState(false);
  const [bonusInput, setBonusInput] = useState(defaultBonus);

  const [update, { loading }] = useMutation<Graph.Mutation>(MUTATE_UPDATE_WAREHOUSE, {
    refetchQueries: ['warehouseList'],
  });

  const toggleActive = useCallback(() => setActive(!active), [active]);

  const activator = (
    <div
      className="flex flex-row items-center justify-between mt-2 hover:bg-gray-50 cursor-pointer"
      onClick={toggleActive}
    >
      <div>Driver Bonus</div>
      <div className="flex flex-row gap-1">
        {Number(defaultBonus || 0) > 0 && <b className="text-sky-800">{defaultBonus}%</b>}
        <div>
          <Icon source={ChevronRightMinor} />
        </div>
      </div>
    </div>
  );

  const handleSave = useCallback(() => {
    const input = {
      id: Number(props.warehouse.id),
      data: {
        driverBonus: String(bonusInput),
      },
    };

    update({
      variables: input,
    })
      .then(res => {
        if (res.data?.updateWarehouse) {
          setToasts([
            ...toasts,
            { content: `Save driver bonus warehouse ${props.warehouse.name}: ${bonusInput}%`, status: 'success' },
          ]);
          toggleActive();
        } else {
          setToasts([...toasts, { content: `Something was wrong please try again!`, status: 'error' }]);
        }
      })
      .catch(() => {
        setToasts([...toasts, { content: `Something was wrong please try again!`, status: 'error' }]);
      });
  }, [bonusInput, update, toggleActive, toasts, setToasts]);

  const handleSaveAllHub = useCallback(() => {
    let count = 0;
    for (const warehouse of props.warehouseList || []) {
      const input = {
        id: Number(warehouse.id),
        data: {
          driverBonus: String(bonusInput),
        },
      };

      update({
        variables: input,
      })
        .then(res => {
          if (res.data?.updateWarehouse) {
            // eslint-disable-next-line no-console
            console.log('Save driver bonus ' + warehouse.name);
          } else {
            setToasts([...toasts, { content: `Something was wrong please try again!`, status: 'error' }]);
          }
        })
        .catch(() => {
          setToasts([...toasts, { content: `Something was wrong please try again!`, status: 'error' }]);
        });
      count += 1;
    }

    if (count === props.warehouseList?.length || 0) {
      setToasts([...toasts, { content: `Save driver bonus ${bonusInput}% to all hub`, status: 'success' }]);
      setTimeout(() => {
        toggleActive();
        window.location.reload();
      }, 500);
    }
  }, [props, update, bonusInput, toasts, setToasts, update]);

  return (
    <Modal
      title={`Driver Bonus: ${props.warehouse.name}`}
      activator={activator}
      open={active}
      onClose={() => !loading && toggleActive()}
      primaryAction={{
        content: 'Save',
        onAction: handleSave,
        loading: loading,
        disabled: loading,
      }}
      secondaryActions={[
        {
          content: 'Save to all hubs',
          onAction: handleSaveAllHub,
        },
      ]}
    >
      <Modal.Section>
        <TextField
          value={bonusInput || ''}
          label="Driver Bonus"
          autoComplete="off"
          type="number"
          suffix={'%'}
          selectTextOnFocus
          onChange={setBonusInput}
        />
      </Modal.Section>
    </Modal>
  );
}
